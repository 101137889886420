export const client: RECO.Mobile.Config.Client = (window["client"] = {
    title: "“浦东青年”综合服务信息化平台",
    techSupport: "技术支持:  上海瑞谷拜特软件技术有限公司",
    urlArgs: "v=180427",
    tel: "021-20231080",
    /**
     * 是否在Header里调用查询工单的接口
     */
    isgetOrderOnHeader: true,
    /**
     * 默认园区code
     */
    parkCode: "gongqtshspdxqwyh",
    /**
     * 作为区分同一域名下不同网页的缓存的关键字
     */
    appDomain: "ipark_web",
    /**
     * 是否使用高德地图
     */
    isUseGDMap: false,
    ...window["client"],
    app: {
        /** 是否开启行政审批 */
        openAdminiApproval: true,
        /** 是否开启招聘 */
        openRecruit: true,
        /** 是否开启企业主页 */
        openEnterpriseHome: true,
        /** 是否政策申报 */
        openPolicyDeclare: true,
    },
    envFlag: 1, // 0测试 1为正式
});

export const server: RECO.Mobile.Config.Server = (window["server"] = {
    apiKey: {
        apiKey: "Bitech\\PC",
        secret: "44678314ba0efa0c",
    },
    apiVersion: 9,
    auth: {
        oauth2Url: "authorization/oauth2",
        autoLogin: !0,
        autoRefreshToken: !0,
        anonymousLogin: !0, ////
    },
    rsa: {
        enable: true,
        publicKey: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXu1ms9TrhrMlIn3iv4g2Pw9Mfr9abdAB-TpDD65G98wjHZxgkZfhzUHE8BSsUDzIHfOiCd4RT5fZtTiop9bzwecgYCrAICjrVC-8ZWKf1RqRX4EXiISyEBHb4YAER_Rt2TQEWGPCi14ujDTA9qr1_YRUFfh52nrc-MfkfC12BwQIDAQAB`,
    },
    appdownURL: client.envFlag ? "" : "//demo.bitech.cn/iparkappjavaalpha/", // app下载地址
    jsonURL: client.envFlag ? "" : "//dev.bitechdevelop.com/reco.ipark.cms/webSeo.json", // seo生成json文件路径

    base: client.envFlag ? "/" : "/pdyouth-website/",
    // xc.pdyouth.net
    // webappurl: client.envFlag ? "http://10.220.196.202:89/pdqn/#/" : "//fat.bitechdevelop.com/pdyouth-webapp/#/", // 后台地址
    webappurl: client.envFlag ? "//www.pdyouth.org/pdqn/#/" : "https://fat.bitechdevelop.com/pdyouth-webapp/#/", // 后台地址
    assetsUrl: client.envFlag ? "//www.pdyouth.org/mobileapi/" : "https://fat.bitechdevelop.com/pdyouth-mobileapi/", // 图片存放地址，用于富文本中获取图片
    url: client.envFlag ? "//www.pdyouth.org/mobileapi/" : "https://fat.bitechdevelop.com/pdyouth-mobileapi/", // 接口地址
    jsURL: client.envFlag ? "//www.pdyouth.org/assets/js" : "https://fat.bitechdevelop.com/pdyouth-website/assets/js", // website js地址
    // webappurl: client.envFlag ? "http://10.220.196.202:89/pdqn/#/" : "//linux.bitechdevelop.com/pdqn/#/", // 后台地址
    // // webappurl: client.envFlag ? "//www.pdyouth.net/pdqn/#/" : "//linux.bitechdevelop.com/pdqn/#/", // 后台地址
    // assetsUrl: client.envFlag ? "//www.pdyouth.net/mobileapi/" : "//linux.bitechdevelop.com/pdqn.mobileapi/", // 图片存放地址，用于富文本中获取图片
    // url: client.envFlag ? "//www.pdyouth.net/mobileapi/" : "//linux.bitechdevelop.com/pdqn.mobileapi/", // 接口地址
    // jsURL: client.envFlag ? "//www.pdyouth.net/assets/js" : "//linux.bitechdevelop.com/pdqn.website/assets/js", // website js地址

    ...window["server"],
});
