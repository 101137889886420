export enum Namespaces {
    memberManager = "memberManager",
    memberIntergral = "memberIntergral",
    memberCertify = "memberCertify",
    certifyForm = "certifyForm",
    certifyCompany = "certifyCompany",
    certifyDetail = "certifyDetail",
    judgeCertify = "judgeCertify",
}

/**
 * 当前积分账号类型
 */
export enum CurrentIntergralTypeEnum {
    /**
     * 个人
     */
    person = "0",
    /**
     * 企业
     */
    company = "1",
}

/**
 * 我的积分-抬头tab枚举
 */
export enum IntergralTitleTabEnum {
    /**
     * 我的积分
     */
    my = "1",
    /**
     * 积分规则
     */
    rule = "2",
}

/**
 * 积分支出类型
 */
export enum IntergralRuleTypeEnum {
    /**
     * 收入
     */
    earn = 0,
    /**
     * 支出
     */
    expend = 1,
}

/**
 * 员工管理
 */
export enum MemberManagerEnum {
    /**
     * 员工管理
     */
    manage = "1",
    /**
     * 员工审核
     */
    audit = "2",
}

/**
 * 认证状态枚举
 */
export enum CertifyStatusEnum {
    /**
     * 未认证
     */
    noCertify = 1,

    /**
     * 待审核
     */
    noConfirm = 2,

    /**
     * 审核通过
     */
    allow = 3,

    /**
     * 审核拒绝
     */
    bounced = 4,
}

/**
 * 内置会员类型名枚举
 */
export enum MemberRoleNameBuiltInEnum {
    /**
     * 管理员
     */
    manager = "企业管理员",

    /**
     * 员工
     */
    employee = "企业员工",
}

/**
 * 内置会员角色代码枚举
 */
export enum MemberRoleCodeBuiltinEnum {
    /**
     * 企业管理员
     */
    manager = "qiygly",
    /**
     * 企业员工
     */
    employee = "qiyyg",
}

/**
 * 客户类型
 */
export enum CustomerTypeEnum {
    /**
     * 企业客户
     */
    company = 1,
    /**
     * 个人客户
     */
    personal = 2,
}

/**
 * 客户状态
 */
export enum CustomerStatusEnum {
    /**
     * 未落地客户
     */
    unDeal = 0,
    /**
     * 已落地客户
     */
    deal = 1,
    /**
     * 离园
     */
    moveOut = 2,
    /**
     * 搁置
     */
    shelve = 3,
}

/**
 * 操作来源
 */
export enum operateSourceEnum {
    /**
     * web
     */
    web = 3,
    /**
     * app
     */
    app = 2,
}

export const RULE_TABS = [
    { title: "积分收入", status: IntergralRuleTypeEnum.earn },
    { title: "积分支出", status: IntergralRuleTypeEnum.expend },
];
export const TITLE_TABS = [
    { tab: "我的积分", key: IntergralTitleTabEnum.my },
    { tab: "积分规则", key: IntergralTitleTabEnum.rule },
];

export const DATE_FORMAT = "YYYY-MM-DD";

// 企业认证取消状态
export enum EnumMemberCertifyCancelStatus {
    /// 取消认证
    Cancel = 1,
    /// 企业解绑
    Remove = 2,
}

export enum certifyStatus {
    notCertified = "未认证",
    pendingReview = "待审核",
    returned = "已退回",
    certified = "已认证",
}

export enum MemberRole {
    COMMON = 1,
    REALNAME = 2,
    COMPANY = 3,
}
