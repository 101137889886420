import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, getLocalStorage, setLocalStorage } from "@reco-m/core";
import { app, getParkCode } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { Namespaces as userNamespaces } from "@reco-w/auth-user-models";
import { INSTITUTION_SERVICE_TYPE_TAG_CODE } from "@reco-w/market-models";
import { TIPS } from "@reco-w/home-common";
import { parkService, searchService } from "@reco-w/home-service";
import { parkCateService } from "@reco-w/impression-service";
import { ARTICLE_ROOT_CODE } from "@reco-w/article-models";
import { articleService } from "@reco-w/article-service";
import { ARTICLE_LIST_DEFAULT_ORDERBY } from "@reco-w/article-models";
import { Namespaces } from "./common";

export namespace headerModel {
    export const namespace = Namespaces.header;

    let articleCatalogs = [];

    export const state: any = freeze(
        {
            ...CoreState,
            dataSource: null,
            currentUser: null,
            parkNames: null,
            radioSelect: getLocalStorage("parkId") ? getLocalStorage("parkId") : "",
            parkId: getLocalStorage("parkId") ? getLocalStorage("parkId") : "",
            parkName: getLocalStorage("parkName") ? getLocalStorage("parkName") : "",
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        /**
         * 初始化页面
         * @param { message, inputData, isPreventGetUser, judgeParkCode }
         * @param { call, put }
         */
        *initPage({ message, inputData, judgeParkCode }, { call, put }) {
            try {
                yield put({ type: "input", data: inputData });
                yield put({ type: "getPark", judgeParkCode, message });
                yield put({ type: "getCertify", message, isgetOrder: client["isgetOrderOnHeader"] });
                yield put({ type: "getServiceCatalogue", message });
                yield put({ type: "getArticleCatalog", message });
            } catch (e) {
                yield call(message!.error, "initPage" + e.errmsg);
            }
        },

        /**
         * 获取认证情况
         * @param { message, isRefreshUser, isgetOrder }
         * @param { call, put, select }
         */
        *getCertify({ message, isRefreshUser, isgetOrder }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, isRefreshUser });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const user = memberCommonState.user;
                const member = memberCommonState.member;
                const currentUser = user.currentUser;

                if (isgetOrder) {
                    yield put({ type: "getCheckInDetail", message, userId: user?.currentUser?.id });
                }

                yield put({ type: "input", data: { user: currentUser, member } });
            } catch (e) {
                yield call(message!.error, "getCertify" + e.errmsg);
            }
        },

        /**
         * 获取园区
         * @param { callback, judgeParkCode, message }
         * @param { call, put }
         */
        *getPark({ callback, judgeParkCode, message }, { call, put }) {
            try {
                const { items } = yield call(parkService.getPaged);
                let parkNames: any[] = [];
                items &&
                    items.length > 0 &&
                    items.forEach((item) => {
                        parkNames.push({ label: item.parkName, value: item.id, code: item.parkCode });
                    });

                // 判断当前园区和缓存中的园区是否一致
                if (judgeParkCode) {
                    const selectedPark = parkNames && parkNames.length > 0 && parkNames.find((x) => x.code === judgeParkCode);
                    const isRefresh = getParkCode() && selectedPark && selectedPark.value.toString() !== getLocalStorage("parkId");
                    if (isRefresh || !getLocalStorage("parkId")) {
                        setLocalStorage("parkId", selectedPark.value);
                        setLocalStorage("parkName", selectedPark.label);
                        setLocalStorage("parkCode", selectedPark.code);
                        // isRefresh && window.location.reload();
                    }
                    yield put({ type: "input", data: { selectedPark } });
                }

                // 不存在缓存时
                if (parkNames && parkNames.length > 0 && !getLocalStorage("parkId") && !getLocalStorage("parkName")) {
                    setLocalStorage("parkId", parkNames[0].value);
                    setLocalStorage("parkName", parkNames[0].label);
                    setLocalStorage("parkCode", parkNames[0].code);
                    yield put({ type: "input", data: { radioSelect: parkNames[0].value, parkName: parkNames[0].label, i: Math.random() } });
                }

                // 不存在parkcode时
                if (getLocalStorage("parkCode")) {
                    if (getLocalStorage("parkId")) {
                        const park = parkNames.find((x) => x.value.toString() === getLocalStorage("parkId"));
                        park && setLocalStorage("parkCode", park.code);
                    } else {
                        setLocalStorage("parkCode", client["parkCode"]);
                    }
                }
                yield put({ type: "input", data: { parkNames } });

                if (callback) yield call(callback);
            } catch (e) {
                yield call(message!.error, "getPark" + e.errmsg);
            }
        },

        /**
         * 获取服务联盟类型
         * @param { message }
         * @param { call, put, select }
         */
        *getServiceCatalogue({ message }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.header]);
                let catalogues = state!.catalogues;
                if (!catalogues) catalogues = yield call(tagService.getTagByTagClass, { tagClass: INSTITUTION_SERVICE_TYPE_TAG_CODE, parkId: getLocalStorage("parkId") });
                yield put({ type: "input", data: { catalogues } });
            } catch (e) {
                yield call(message!.error, "getServiceCatalogue" + e.errmsg);
            }
        },

        /**
         * 获取服务联盟类型
         * @param { message }
         * @param { call, put, select }
         */
        *getArticleCatalog({ message }, { call, put }) {
            try {
                if (!articleCatalogs?.length) {
                    // authorized: false,
                    articleCatalogs = yield call(parkCateService.getList, { rootCode: ARTICLE_ROOT_CODE });
                }

                yield put({ type: "input", data: { articleCatalogs } });
            } catch (e) {
                yield call(message!.error, "getServiceCatalogue" + e.errmsg);
            }
        },

        /**
         * 获取全局搜索
         * @param { error, data, callback }
         * @param { call, put }
         */
        *getGlobalSearch({ error, data, callback }, { call, put }) {
            try {
                if (data && data.key) {
                    yield put({ type: "input", data: { globalData: null } });
                    const globalData = yield call(searchService.getSearchData, {
                        ...data,
                        unitId: getLocalStorage("UnitID"),
                        parkId: getLocalStorage("parkId"),
                        pageSize: 1,
                    });
                    let arr: any = [];
                    TIPS.forEach((tip) => {
                        if (globalData[tip.key]) {
                            if (globalData[tip.key].totalItems !== "0" && globalData[tip.key].totalItems !== 0) {
                                arr.push({
                                    type: tip.type,
                                    query: tip.name,
                                    count: globalData[tip.key].totalItems && Number(globalData[tip.key].totalItems),
                                });
                            }
                        } else {
                            return false;
                        }
                    });
                    yield put({ type: "input", data: { globalData: arr } });

                    if (callback) yield call(callback, arr);
                } else {
                    yield put({ type: "input", data: { globalData: [] } });
                }
            } catch (e) {
                yield call(error, "Head-getGlobalSearch：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取 入驻申请  工单
         * @param { data, message, userId }
         * @param { call, put, select }
         */
        *getCheckInDetail({ data, userId, message }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.workorderCommon}/getCheckInDetail`, data, userId });
                const workorderCommonState: any = yield select((state) => state[commonNamespaces.workorderCommon]),
                    checkInData = workorderCommonState.checkInData;

                const checkOrderId = checkInData?.isNoCheckStatus ? checkInData && checkInData.checkOrderId : null,
                    checkStateId = checkInData?.isNoCheckStatus ? checkInData && checkInData.checkStateId : null;

                yield put({ type: "input", data: { checkInData, checkOrderId, checkStateId } });
            } catch (e) {
                yield call(message!.error, "getCheckInDetail" + e.errmsg);
            }
        },

        /**
         * 登出
         * @param { error, success }
         * @param { call, put }
         */
        *logout({ error, success }, { call, put }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/clean` });
                yield yield put({ type: `${commonNamespaces.workorderCommon}/clean` });

                yield put({ type: `${userNamespaces.user}/logout`, success });
            } catch (e) {
                yield call(error, "Head-logout" + e.errmsg);
            }
        },
        /**
         * Get article list
         * @param { error, data }
         * @param { call, put }
         */
        *getArticleList({ error, data, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                let articleList = yield call(articleService.getPaged, {
                    parkId: getLocalStorage("parkId"),
                    orderBy: ARTICLE_LIST_DEFAULT_ORDERBY,
                    isPublish: true,
                    ...data,
                });
                callback && callback(articleList);
                return articleList;
            } catch (e) {
                console.error(e);
                yield call(error, "getArticleList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

export function registerPageHeaderModel(model?: any) {
    app.model(model || headerModel);
}
