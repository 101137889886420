import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, formatDate, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";

import { CertifyStatusEnum, Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberCompanyUserTypeService, memberService } from "@reco-w/member-service";
import { MemberRoleCodeBuiltinEnum } from "@reco-w/member-models";
import { marketService } from "@reco-w/market-service";

import { InstitutionSourceEnum, SceneConfigEnum, INSTITUTION_SERVICE_TYPE_TAG_CODE, Namespaces } from "./common";

export namespace marketInModel {
    export const namespace = Namespaces.MyMarketIn;

    export const state: any = freeze(
        {
            isAgree: true,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ message, companyId, isupdate, institutionCallback, sceneCallback, validateMobileError }, { call, put, select }) {
            try {
                yield put({ type: "init" });
                yield put({ type: "getMarketTag", message });
                yield yield put({ type: "getAcceptanceMode", message });
                yield put({ type: "getProtocol", message });
                const state: any = yield select((state) => state[Namespaces.MyMarketIn]);
                const institutionMode = state!.institutionMode;

                if (institutionMode === SceneConfigEnum.institutionAccept) {
                    yield put({ type: "getCertifyAndInstitution", companyId, message, institutionCallback, isupdate, validateMobileError });
                } else {
                    if (sceneCallback) {
                        yield call(sceneCallback);
                    }
                }
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },

        /**
         * 获取受理模式
         */
        *getAcceptanceMode({ message }, { call, put }) {
            try {
                const institutionMode = yield call(marketService.getAcceptanceMode);

                yield put({ type: "input", data: { institutionMode } });
            } catch (e) {
                yield call(message!.error, "getAcceptanceMode：" + e.errmsg);
            }
        },

        /**
         * 获取服务机构协议
         */
        *getProtocol({ message }, { call, put }) {
            try {
                const protocol = yield call(marketService.getProtocol);

                yield put({ type: "input", data: { protocol } });
            } catch (e) {
                yield call(message!.error, "getProtocol：" + e.errmsg);
            }
        },

        /**
         * 获取认证信息
         */
        *getCertifyAndInstitution({ message, companyId, isupdate, institutionCallback, validateMobileError }, { call, put, select }) {
            try {
                yield yield put({ type: `${Namespaces.market}/getCertifyAndInstitution` });
                const marketState: any = yield select((state) => state[Namespaces.market]);
                const currentMember = marketState.certify;
                const marketDetail = marketState.marketDetail;

                yield put({ type: "input", data: { currentMember } });

                if (companyId && companyId !== "0") {
                    yield put({ type: "getCustomerMember", companyId, message });

                    yield put({
                        type: "input",
                        data: {
                            companyId,
                            selectedContactAccountId: currentMember?.accountId,
                            contactPerson: currentMember?.realName,
                            mobile: currentMember?.mobile,
                            organizationName: currentMember.companyName,
                            businessCode: currentMember.creditCode,
                        },
                    });
                }
                yield put({
                    type: "input",
                    data: {
                        contactPerson: currentMember.realName,
                        mobile: currentMember.mobile,
                    },
                });
                yield put({ type: "handleInstitution", institution: marketDetail, isupdate });

                if (!isupdate) {
                    yield put({ type: "getValidateMobile", mobile: currentMember?.mobile, error: validateMobileError });
                }

                if (institutionCallback) {
                    yield call(institutionCallback, marketDetail, currentMember);
                }
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            }
        },

        /**
         * 获取认证信息
         */
        *getCustomerMember({ message, companyId }, { call, put }) {
            try {
                if (companyId && companyId !== "0") {
                    const roles = yield call(memberCompanyUserTypeService.getList, { code: MemberRoleCodeBuiltinEnum.manager });
                    if (roles.length) {
                        const contacts = yield call(memberService.getCompanyStaffList, {
                            status: CertifyStatusEnum.allow,
                            parkId: getLocalStorage("parkId"),
                            companyId: companyId,
                            companyUserTypeId: roles[0].id,
                            pageSize: 9999,
                        });
                        yield put({ type: "input", data: { contacts: contacts.items } });
                    }
                }
            } catch (e) {
                yield call(message!.error, "getCertify：" + e.errmsg);
            }
        },

        /**
         * 如果是重新提交，对数据进行处理
         */
        *handleInstitution({ message, institution, isupdate, callBack }, { call, put }) {
            try {
                //
                // 服务机构获取详情重新提交
                const {
                    serviceInstitutionCategoryDetailVMList: insCategory = [],
                    serviceInstitutionBasicFormVM: insBasic = {},
                    contactPersonalCommonVM: personCommon = {},
                    serviceAllianceSubmitOrderVM: subOrder = {},
                } = institution || {};
                let chosenCatalogueValue = insCategory?.map((x) => x.serviceCategoryValue);
                if (isupdate) {
                    yield put({
                        type: "input",
                        data: {
                            organizationName: insBasic.institutionName,
                            chosenCatalogueValue: chosenCatalogueValue,
                            address: personCommon.address,
                            contactPerson: personCommon.fullName,
                            mobile: personCommon.mobile,
                            registDate: formatDate(subOrder.inputTime, "yyyy-MM-dd"),
                            businessCode: insBasic.creditCode,
                            email: personCommon.email,
                            tel: personCommon.tel,
                            summary: insBasic.detail,
                            caseinfo: insBasic.serviceCase,
                            bindTableId: personCommon.bindTableId,
                            website: insBasic.website,
                        },
                    });
                    // 编辑加载附件
                    if (insBasic && insBasic.id && callBack) {
                        yield call(callBack, insBasic.id);
                    }
                }
            } catch (e) {
                yield call(message!.error, "handleInstitution：" + e.errmsg);
            }
        },

        /**
         * 获取服务类别
         */
        *getMarketTag({ message }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                let catalogues = yield call(tagService.getTagByTagClass, { tagClass: INSTITUTION_SERVICE_TYPE_TAG_CODE, parkId: getLocalStorage("parkId") });

                catalogues = catalogues?.map((item) => ({ ...item, label: item.tagName, value: item.tagValue }));

                yield put({ type: "input", data: { catalogues: catalogues } });
            } catch (e) {
                yield call(message!.error, "getMarketTag：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 服务机构校验手机
         * @param { error, mobile, callback }
         * @param { call }
         */
        *getValidateMobile({ error, mobile, callback }, { call }) {
            try {
                yield call(marketService.getValidateMobile, mobile);

                if (callback) {
                    yield call(callback);
                }
            } catch (e) {
                yield call(error, e.errmsg);
            }
        },

        /**
         * 提交服务机构入驻申请
         */
        *commitFrom({ message, callback, companyId, isupdate }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                const { marketIn } = yield select((state) => ({ marketIn: state[Namespaces.MyMarketIn] })),
                    catalogues = marketIn.catalogues || [],
                    chosenCatalogueValue = marketIn.chosenCatalogueValue || [];

                let contactPersonalCommonVM = {
                    address: marketIn.address,
                    fullName: marketIn.contactPerson,
                    mobile: marketIn.mobile,
                    email: marketIn.email,
                    tel: marketIn.tel,
                };
                let serviceInstitutionBasicFormVM = {
                    website: marketIn.website,
                    clientSource: InstitutionSourceEnum.web,
                    creditCode: marketIn.businessCode,
                    detail: marketIn.summary,
                    institutionName: marketIn.organizationName,
                    parkId: getLocalStorage("parkId"),
                    parkName: getLocalStorage("parkName"),
                    serviceCase: marketIn.caseinfo,
                    setupDate: marketIn.registDate || new Date(),
                    status: 0,
                    companyId: companyId && companyId !== "0" ? companyId : null,
                };
                let serviceInstitutionCategoryDetailVMList = [] as any;
                const chosenCatalogue = catalogues?.filter((item) => chosenCatalogueValue.contains(item.tagValue));

                chosenCatalogue &&
                    chosenCatalogue.forEach((item) => {
                        serviceInstitutionCategoryDetailVMList.push({
                            serviceCategory: item.tagName,
                            serviceCategoryValue: item.tagValue,
                        });
                    });

                let submitResult;
                yield yield put({ type: `${commonNamespaces}/cleanInstitution` });
                if (isupdate) {
                    submitResult = yield call(marketService.put, marketIn.bindTableId, {
                        contactPersonalCommonVM,
                        serviceInstitutionBasicFormVM,
                        serviceInstitutionCategoryDetailVMList,
                    });
                    if (callback) {
                        yield call(callback!, marketIn.bindTableId);
                    }
                } else {
                    submitResult = yield call(marketService.post, {
                        contactPersonalCommonVM,
                        serviceInstitutionBasicFormVM,
                        serviceInstitutionCategoryDetailVMList,
                    });
                    if (callback) {
                        yield call(callback!, submitResult);
                    }
                }
            } catch (e) {
                yield call(message!.error, e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(marketInModel);
