import React from "react";
import { Button } from "antd";

/**
 * 渲染搜索栏需要用到展开按钮
 * @param self
 * @param id  组件id
 * @param showInfosMap  是否已经展开
 * @returns
 */
export function renderSearchPanelOpenButton(self, id, showInfosMap): React.ReactNode {
    const { state } = self.props,
        openInfos = state!["openInfos_" + id],
        showInfos = state![showInfosMap];
    return openInfos ? (
        showInfos ? (
            <Button onClick={() => self.dispatch({ type: "input", data: { [showInfosMap]: false } })} size={"small"}>
                收起
            </Button>
        ) : (
            <Button onClick={() => self.dispatch({ type: "input", data: { [showInfosMap]: true } })} size={"small"}>
                展开
            </Button>
        )
    ) : null;
}
