import { transformAssetsUrl } from "@reco-m/core";
import { changeUrlParams } from "@reco-w/core-ui";
import { CommonSearchParamInUrlEnum } from "./ipark.common.enum";
/**
 * 获取封面图片
 * @param item
 * @param [isAvatar] 是否是头像，获取不同的默认图片，默认值是false
 * @returns
 */
export function getCoverPicture(item, isAvatar = false) {
    if (!item) {
        return "";
    }

    if (isAvatar) {
        if (item.avatarPicUrl || item.avatarPath || item.avatarPictureUrl || item.profilePath) {
            return transformAssetsUrl(item.avatarPicUrl || item.avatarPath || item.avatarPictureUrl || item.profilePath);
        } else {
            return "assets/images/myBackground.jpg";
        }
    } else if (item.coverPictureUrl || item.coverUrl) {
        return transformAssetsUrl(item.coverPictureUrl || item.coverUrl);
    } else if ((item.pictureUrlList || item.pictureList)?.length > 0) {
        return transformAssetsUrl((item.pictureUrlList || item.pictureList)[0]);
    } else {
        return "assets/images/nopic.jpg";
    }
}
/**
 * 获取上/下条相关信息
 * @param list 列表
 * @param curId 当前信息Id，用于在list中获取当前位置，从而获取上下条信息
 * @param [titleMap] 绑定的标题字段名，默认为title
 * @param [titleOfNoPre] 没有上一条信息时的标题，默认为 没有上一篇
 * @param [titleOfNoNext] 没有下一条信息时的标题，默认为 没有下一篇
 * @returns
 */
export function getPreviousOrNextInfo(list: any[], curId: any, titleMap = "title", titleOfNoPre = "没有上一篇", titleOfNoNext = "没有下一篇") {
    let index: number; // 当前信息所在位置
    let preTitle: string = ""; // 上一条标题
    let nextTitle: string = ""; // 下一条标题
    let preID: number = 0; // 上一条id
    let nextID: number = 0; // 下一条id

    if (list?.length > 0 && curId) {
        index = list.findIndex((item) => item!.id === curId);
        preTitle = index - 1 >= 0 ? list[index - 1][titleMap] : titleOfNoPre;
        nextTitle = index === list.length - 1 ? titleOfNoNext : list[index + 1][titleMap];
        preID = index - 1 >= 0 ? list[index - 1] && list[index - 1].id : 0;
        nextID = index === list.length - 1 ? 0 : list[index + 1] && list[index + 1].id;
    }

    return { preTitle, nextTitle, preID, nextID };
}

/**
 * 页面跳转
 */
export function changePage(currentPage, pageSize, self) {
    self.goTo(changeUrlParams(window.location.search, { [CommonSearchParamInUrlEnum.pageIndex]: currentPage, [CommonSearchParamInUrlEnum.pageSize]: pageSize }));

    window.scroll(0, 0);
}

/**
 * 搜索
 * @param self 页面this
 * @param name 标签id
 */
export function searchBox(self, id) {
    let searchHeight = $(`#${id}`);
    if (searchHeight.outerHeight()! > 60) {
        self.dispatch({
            type: "input",
            data: { ["openInfos_" + id]: true },
        });
    }
}
