import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { marketService } from "@reco-w/market-service";

import { Namespaces } from "./common";

export namespace mymenuModel {
    export const namespace = Namespaces.myMenu;

    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ error }, { call, put }) {
            put({ type: "showLoading" });
            try {
                yield put({ type: "getCertify", error, isgetOrder: client["isgetOrderOnHeader"] });
            } catch (e) {
                yield call(error, "Menu-initPage：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *getCertify({ error, isgetOrder, isRefreshUser }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, isRefreshUser });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState.user,
                    member = memberCommonState.member;
                // certifyDetail = memberCommonState.get("certifyDetail");
                yield put({ type: "input", data: { member: member.id ? member : null, user } });

                if (isgetOrder) {
                    yield put({ type: "getInstitution", companyId: member && member.companyId });
                    yield put({ type: "getCheckInDetail", userId: user && user.currentUser && user.currentUser.id });
                }
            } catch (e) {
                yield call(error, "Menu-getCertify" + e.errmsg);
            }
        },
        *getCheckInDetail({ data, error, userId }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.workorderCommon}/getCheckInDetail`, data, userId });
                const workorderCommonState: any = yield select((state) => state[commonNamespaces.workorderCommon]),
                    checkInData = workorderCommonState.checkInData;

                const checkOrderId = checkInData?.isCheckCancel ? checkInData?.checkOrderId : null,
                    checkStateId = checkInData?.isCheckCancel ? checkInData?.checkStateId : null;

                yield put({ type: "input", data: { checkInData, checkOrderId, checkStateId } });
            } catch (e) {
                yield call(error, "Menu-getCheckInDetail" + e.errmsg);
            }
        },

        *getInstitution({ error }, { call, put }) {
            try {
                const result = yield call(marketService.getMyInstitution);

                yield put({ type: "input", data: { institution: result } });
            } catch (e) {
                yield call(error, "Menu-getInstitution" + e.errmsg);
            }
        },
    };
}

export function registerMyMenuModel(model?: any) {
    app.model(model || mymenuModel);
}
