export enum DemandSubmissionTagEnum {
    // 所在区域
    suoziquyu = "YOUTHINFO/SUOZQY",

    // 需求类型
    xuqiuleixing = "DEMANDREPORTING/XUQLX",

    // 性别
    xingBie = "DEMANDREPORTING/XINGB",

    // 处理状态
    chuliZhuangTai = "DEMANDREPORTING/CHULZT",

    // 处理状态
    zhengzmm = "ACCOUNT/zhengzmm",
}
