import { HttpService, resolveService } from "@reco-m/core";

/**
 * 组织库 https://linux.bitechdevelop.com/pdqn.mobileapi/organization/organization

 */
export class OrganizationHttpService extends HttpService {
    constructor() {
        super("organization/organization");
    }
}
export const organizationService = resolveService(OrganizationHttpService);
