export const menuLabel = {
    GZYX: {
        label: "工作要讯",
    },
    JCDT: {
        label: "基层动态",
        tagClass: "INFORMATION/JICDT",
    },
    TZTG: {
        label: "通知公告",
    },
    BSZN: {
        label: "部室职能",
        tagClass: "INFORMATION/BUSZN",
    },
    QNDXX: {
        label: "青年大学习",
        tagClass: "INFORMATION/QINGNDXX",
    },
    PDQNCP: {
        label: "浦东青年出品",
        tagClass: "INFORMATION/PUDQNCP",
    },
    XMTJZ: {
        label: "新媒体矩阵",
        tagClass: "INFORMATION/XINMTJZ",
    },
    QMGC: {
        label: "青马工程",
    },
    GQXCZ: {
        label: "国旗下成长",
    },
    JYJPD: {
        label: "菁英聚浦东",
        tagClass: "INFORMATION/JINGYJPD",
    },
    QNSLJ: {
        label: "青年生力军",
        tagClass: "INFORMATION/QINGNSLJ",
    },
    CXCY: {
        label: "创新创业",
        tagClass: "INFORMATION/CHUANGXCY",
    },
    GWJG: {
        label: "岗位建功",
        tagClass: "INFORMATION/GANGWJG",
    },
    JYQH: {
        label: "就业启航",
    },
    QCZYH: {
        label: "青春志愿汇",
        tagClass: "INFORMATION/QINGCZYH",
    },
    QSSL: {
        label: "青社沙龙",
    },
    QCPPC: {
        label: "青春篷篷车",
    },
    QCHHR: {
        label: "青创合伙人",
    },
    QCSHZ: {
        label: "青春守护者",
        tagClass: "INFORMATION/QINGCSHZ",
    },
    TJA: {
        label: "团聚爱",
    },
    QNJKJ: {
        label: "青年健康节",
    },
    QZGY: {
        label: "亲子共育",
    },
    MTJJ: {
        label: "媒体聚焦",
    },
    SJBZ: {
        label: "书记班子",
    },
    ZLZX: {
        label: "资料中心",
        tagClass: "INFORMATION/ZILZX",
    },
    dasjhry: {
        label: "大事记和荣誉",
    },
    wangstx: {
        label: "网上团校",
    },
    pudqnwxgzhrw: {
        label: "浦东青年微信公众号热文",
    },
    QNYSSW: {
        label: "青年与十四五",
    },
};

export const menuTree = [
    {
        label: "组织机构",
        children: [
            {
                label: "书记班子",
                value: "SJBZ",
                path: "",
            },
            {
                label: "部室职能",
                value: "BSZN",
                path: "",
            },
        ],
    },
    {
        label: "工作动态",
        children: [
            {
                label: "工作要讯",
                value: "GZYX",
                path: "",
            },
            {
                label: "基层动态",
                value: "JCDT",
                path: "",
            },
        ],
    },
];

export const getMenuTreeByCode = (code: string) => {
    const targetTree = menuTree.find((menu) => menu.children.find((cmenu) => cmenu.value === code));
    if (!targetTree) {
        return [];
    }
    const parentMenu = targetTree.children.find((item) => item.value === code);
    return [
        { label: targetTree.label, path: "" },
        { label: parentMenu!.label, path: parentMenu!.path },
    ];
};

export function getTagImgPathByName(imgName) {
    // console.log(`assets/images/zixun/${imgName}`);
    return `assets/images/zixun/${imgName}`;
}

export const imgFromTag = {
    aixstb: "aixstb.jpg",
    daxssfw: "daxssfw.jpg",
    lajfl: "lajfl.jpg",
    weilfw: "weilfw.jpg",
    "qingnwmh、tjd、qnaqsfgd": "qingnwmh、tjd、qnaqsfgd.jpg",
    qingnwmhtjdqnaqsfgd: "qingnwmhtjdqnaqsfgd.jpg",
    qingnjnds: "qingnjnds.jpg",
    kaizshzyhxjzgxcsj: "kaizshzyhxjzgxcsj.jpg",
    qingsnfwhqywhgz: "qingsnfwhqywhgz.jpg",
    jiaqtdzzjs: "jiaqtdzzjs.jpg",
    jictzzhljs: "jictzzhljs.jpg",
    tuanzyqndxxwszttk: "tuanzyqndxxwszttk.jpg",
    qingnjst: "qingnjst.jpg",
    "qingnxxs/xxt": "qingnxxs/xxt.jpg",
    bangs: "bangs.png",
    jicgzbqyb: "jicgzbqyb.png",
    "jicgzb（qyb）": "jicgzb（qyb）.png",
    jicgzb: "jicgzb.png",
    chuanmgzb: "chuanmgzb.png",
    "rencgzb（qlmsc）": "rencgzb（qlmsc）.png",
    rencgzbqlmsc: "rencgzbqlmsc.png",
    rencgzb: "rencgzb.png",
    xuesgzb: "xuesgzb.png",
    qingsnfzfwzx: "qingsnfzfwzx.png",
    qingnycypyjh: "qingnycypyjh.jpg",
    "“zzjh”dxssqsjd": "“zzjh”dxssqsjd.jpg",
    zzjhdxssqsjd: "zzjhdxssqsjd.jpg",
    linpdsjpxjxfzs: "linpdsjpxjxfzs.jpg",
    pudsdyxqnqyjxf: "pudsdyxqnqyjxf.jpg",
    zhangsjcsqqnrccxltjqnyccxy: "zhangsjcsqqnrccxltjqnyccxy.jpg",
    "jingykpd（wjqnkpd）": "jingykpd（wjqnkpd）.jpg",
    jingykpdwjqnkpd: "jingykpdwjqnkpd.jpg",
    jingykpd: "jingykpd.jpg",
    qingsnqybh: "qingsnqybh.jpg",
    qingsnqybh1: "qingsnqybh1.jpg",
    dongrny: "dongrny.jpg",
    qingsnfzxc: "qingsnfzxc.jpg",
    Bz: "Bz.png",
    weixgzh: "weixgzh.jpg",
    douy: "douy.jpg",
    pudqn: "pudqn.png",
    daxskcjjsb: "daxskcjjsb.png",
    lijcxcyds: "lijcxcyds.JPG",
    zhuantjksp: "zhuantjksp.jpg",
    yuancMV: "yuancMV.jpg",
    jingpwtk: "jingpwtk.png",
    haib: "haib.jpg",
    donghp: "donghp.png",
};

export function secondCode(code = "") {
    const cq1 = (code || "").split("（").join(""),
        cq2 = (cq1 || "").split("）").join(""),
        cq3 = (cq2 || "").split("、").join(""),
        cq4 = (cq3 || "").split("“").join(""),
        cq5 = (cq4 || "").split("”").join("");
    return cq5;
}

export const secondTagHelper = {
    getTagImagePathByCode: (code) => getTagImgPathByName(imgFromTag[secondCode(code)]),
    isHasImage: (code) => imgFromTag[code],
};
