export enum Namespaces {
    approvalState = "approvalState",
    approvalDatasource = "approvalDatasource",
    approvalStateSubmit = "approvalStateSubmit",
    approvalStateDetail = "approvalStateDetail",
    approvalStateHtml = "approvalStateHtml",
    approvalStateHtmlDetail = "approvalStateHtmlDetail",
}

/**
 * 控件类型
 */
export enum FlowStorageRegionEnum {
    /**
     * 普通文本框
     */
    text = "TEXT",
    /**
     * 意见文本框
     */
    textSign = "TEXT_SIGN",
    /**
     * 会签意见框
     */
    textFot = "TEXT_FOT",
    /**
     * 传阅意见框
     */
    textReadFot = "TEXT_READ_FOT",
    /**
     * 签名区域
     */
    textSignature = "TEXT_SIGNATURE",
    /**
     * 手写签名区域
     */
    textHandSignature = "TEXT_HAND_SIGNATURE",
    /**
     * 正文上传
     */
    mainFile = "MAIN_FILE",
    /**
     * 附件上传
     */
    attachFile = "ATTACH_FILE",
    /**
     * 选人
     */
    chooseUser = "CHOOSE_USER",
    /**
     * 选部门
     */
    chooseDept = "CHOOSE_DEPT",
    /**
     * 展示区域
     */
    showArea = "SHOW_AREA",
    /**
     * 下拉框
     */
    dropDown = "DROP_DOWN",
    /**
     * 复选框
     */
    checkBox = "CHECK_BOX",
    /**
     * 单选框
     */
    radio = "RADIO",
    /**
     * 多行文本框
     */
    textAreaRich = "TEXT_AREA_RICH",
    /**
     * 富文本编辑器
     */
    richText = "TEXT_SIGN",
    /**
     * 图片上传
     */
    picture = "MAIN_FILE",
}

/**
 * 触发事件类型
 */
export enum FlowFormEventEnum {
    /**
     * 单击
     */
    click = "CLICK",
    /**
     * 获得焦点
     */
    focusIn = "FOCUS_IN",
    /**
     * 失去焦点
     */
    focusOut = "FOCUS_OUT",
    /**
     * 输入
     */
    input = "INPUT",
    /**
     * 加载
     */
    load = "LOAD",
    /**
     * 值改变
     */
    change = "CHANGE",
}

/**
 * 响应事件类型
 */
export enum FlowFormActionEnum {
    /**
     * 选择字典
     */
    dict = "DICT",
    /**
     * 选择标签
     */
    tag = "TAG",
    /**
     * 选择时间
     */
    date = "DATE",
    /**
     * 选择编号
     */
    flowNo = "FLOW_NO",
    /**
     * 运行脚本
     */
    script = "SCRIPT",
    /**
     * 选择人员
     */
    user = "USER",
    /**
     * 选择部门
     */
    dept = "DEPT",
    /**
     * API接口
     */
    api = "API",
    /**
     * 自定义
     */
    custom = "CUSTOM",
    /**
     * 级联标签
     */
    cascade = "CASCADE",
    /**
     * 开关控制
     */
    switch = "SWITCH",
}

/**
 * 表单数据类型
 */
export enum FlowFormDataTypeEnum {
    /**
     * 字符串
     */
    string = "STRING",
    /**
     * 数字
     */
    number = "NUMBER",
    /**
     * 日期
     */
    date = "DATE",
    /**
     * 文件
     */
    file = "FILE",
}

/**
 * 表单元素类型
 */
export enum FlowFormItemTypeEnum {
    /**
     * 动态新增行
     */
    table = "table",
    /**
     * 普通文本框
     */
    input = "input",
    /**
     * 日期
     */
    date = "date",
    /**
     * 时间
     */
    time = "time",
    /**
     * 日期时间
     */
    datetime = "datetime",
    /**
     * 多行文本框
     */
    textarea = "textarea",
    /**
     * 图片
     */
    picture = "picture",
    /**
     * 附件
     */
    file = "file",
    /**
     * 多选
     */
    checkbox = "checkbox",
    /**
     * 单选
     */
    radio = "radio",
    /**
     * 文本
     */
    text = "text",
    /**
     * html
     */
    html = "html",
    /**
     * 下拉
     */
    select = "select",
    /**
     * 数字
     */
    number = "number",
}

/**
 * 数据源参数标签
 */
export enum FlowDataSourceTagEnum {
    /**
     * 系统变量
     */
    system = 0,
    /**
     * 表单变量
     */
    form = 1,
    /**
     * URL参数
     */
    url = 2,
    /**
     * 自定义常量
     */
    const = 3,
}

/**
 * 数据源类型
 */
export enum FlowDataSourceCommandTypeEnum {
    /**
     * 存储过程
     */
    stored = 0,
    /**
     * 自定义SQL
     */
    sql = 1,
    /**
     * Api接口
     */
    api = 2,
}

/**
 * 流程校验规则类型
 */
export enum FlowFormValidationTypeEnum {
    /**
     * 数值比较
     */
    compare = "COMPARE",
    /**
     * 正则匹配
     */
    regex = "REGEX",
    /**
     * API调用
     */
    api = "API",
    /**
     * 自定义脚本
     */
    script = "SCRIPT",
    /**
     * 系统规则
     */
    system = "SYSTEM",
}

/**
 * 操作表现形式
 */
export enum FlowProcessOperateShowTypeEnum {
    /**
     * 弹框提示
     */
    confirm = 1,
    /**
     * 弹框输入
     */
    model = 2,
    /**
     * 全屏输入
     */
    drawer = 3,
}

/**
 * 路由条件操作
 */
export enum FlowProcessConditionOperationEnum {
    /**
     * 大于
     */
    greaterThan = "GREATER_THAN",
    /**
     * 大于等于
     */
    greaterThanOrEqual = "GREATER_THAN_OR_EQUAL",
    /**
     * 等于
     */
    equal = "EQUAL",
    /**
     * 小于
     */
    lessThan = "LESS_THAN",
    /**
     * 小于等于
     */
    lessThanOrEqual = "LESS_THAN_OR_EQUAL",
    /**
     * 不等于
     */
    notEqual = "NOT_EQUAL",
    /**
     * 包含
     */
    contains = "CONTAINS",
    /**
     * 不包含
     */
    notContains = "NOT_CONTAINS",
    /**
     * 为空
     */
    null = "NULL",
    /**
     * 不为空
     */
    notNull = "NOT_NULL",
}

/**
 * 路由条件逻辑
 */
export enum FlowProcessConditionLogicalEnum {
    /**
     * 且
     */
    and = "AND",
    /**
     * 或
     */
    or = "OR",
}

/**
 * 按钮样式
 */
export enum ButtonTypeEnum {
    /**
     * 默认
     */
    default = 1,
    /**
     * 列表
     */
    list = 2,
    /**
     * 详情页
     */
    detail = 3,
    /**
     * Tips
     */
    tips = 4,
}

/**
 * 流程显示模式
 */
export enum FlowDisplayModeEnum {
    /**
     * 表单
     */
    form = 0,

    /**
     * 列表
     */
    list = 1,

    /**
     * 问卷
     */
    questionnaire = 2,
}

/**
 * 表单控件值
 */
export enum FlowControlValueTypeEnum {
    /**
     * 系统变量
     */
    variable = "VARIABLE",
    /**
     * 自定义常量
     */
    const = "CONST",
    /**
     * 系统函数
     */
    function = "FUNCTION",
    /**
     * Url参数
     */
    urlPara = "URL_PARA",
}

/**
 * 表单控件类型
 */
export enum FlowFormControlTypeEnum {
    /**
     * 普通文本框
     */
    input = "TextControl",
    /**
     * 数字控件
     */
    number = "NumberControl",
    /**
     * 日期控件
     */
    date = "DateControl",
    /**
     * 多行文本框
     */
    textarea = "TextAreaControl",
    /**
     * 单选
     */
    radio = "RadioControl",
    /**
     * 多选
     */
    checkbox = "CheckboxControl",
    /**
     * 下拉
     */
    select = "SelectControl",
    /**
     * 附件
     */
    attach = "MainFileControl",
}
