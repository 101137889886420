import { HttpService, resolveService } from "@reco-m/core";

/**
 * 资讯（文章）管理
 */
export class ArticleHttpService extends HttpService {
    constructor() {
        super("article/article");
    }
}

export const articleService = resolveService(ArticleHttpService);
