import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreState, CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { notificationService, notificationSceneService } from "@reco-w/notice-service";
import { articleService } from "@reco-w/article-service";
import { surveyAnswerService } from "@reco-w/survey-service";

import { Namespaces, NotificationTypesEnum, MailBoxTypeEnum } from "./common";
import { isJump, SceneCode } from "./utils";

export namespace noticeModel {
    export const namespace = Namespaces.notice;
    export const state: any = freeze({
        ...CoreState,
    });
    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ error, callback }, { call, put }) {
            put({ type: "showLoading" });
            try {
                yield put({ type: "init" });
                yield put({ type: "getAllNotificationSence", error, callback });
                yield put({ type: "getCertify", callback, error });
            } catch (e) {
                yield call(error, "initPage：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        *getCertify({ error }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, error });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    member = memberCommonState.member;

                yield put({ type: "input", data: { member: member } });
            } catch (e) {
                yield call(error, "getCertify：" + e.errmsg);
            }
        },
        *getAllNotificationSence({ callback }, { call, put }) {
            try {
                const allNotificationScene = yield call(notificationSceneService.getAllScene, { productCode: "IPARK" });

                let sceneId: any[] = [];
                let scenes: any[] = [{ sceneName: "全部消息类型", id: "" }];
                allNotificationScene.forEach((item) => {
                    if (SceneCode.includes(item.sceneCode)) {
                        scenes.push(item);
                        sceneId.push(item.id);
                    }
                });

                scenes.length > 0 &&
                    scenes.forEach((x) => {
                        x.label = x.sceneName;
                        x.value = x.id;
                    });

                yield put({ type: "input", data: { sceneId, allNotificationScene, scenes } });

                if (callback) yield call(callback, allNotificationScene);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *getNotificationList({ error, data, sceneIDs }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, error });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState!.user,
                    currentUser = user && user!.currentUser;

                const state = yield select((state) => state[Namespaces.notice]);
                const result = yield call(notificationService.getPaged, {
                    ownerId: currentUser.id,
                    notificationType: NotificationTypesEnum.unRead,
                    mailbox: MailBoxTypeEnum.mailBox2,
                    parkId: getLocalStorage("parkId"),
                    sceneId: sceneIDs ? sceneIDs : state!.sceneId,
                    ...data,
                });

                // 调用读消息接口
                for (let i = 0; i < result?.items?.length; i++) {
                    const item = result.items[i];
                    if (!item.delivery.isRead) {
                        yield put({ type: "readNotificationAction", detailID: item.delivery.id });
                    }
                    item.isNotJump = isJump(item)?.isNotJump;
                }

                yield put({ type: "input", data: { NoticeList: result.items, totalItems: result.totalItems, currentPage: result.currentPage } });
            } catch (e) {
                yield call(error, "getNotificationList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        // 已读
        *readNotificationAction({ error, detailID }, { call, put }) {
            try {
                yield call(notificationService.readNotification, detailID);
            } catch (e) {
                yield call(error, "readNotificationAction：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *getArticleDetail({ error, data, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });

                const articleDetail = yield call(articleService.get, data);

                if (callback) yield call(callback, articleDetail);
            } catch (e) {
                yield call(error, "getArticleDetail：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        *getSurveyAnswer({ error, data, callback }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, error });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]),
                    user = memberCommonState!.user,
                    currentUser = user && user.currentUser;
                const surveyAnswer = yield call(surveyAnswerService.getPaged, data);
                let survey;

                if (surveyAnswer && surveyAnswer.items && surveyAnswer.items.length > 0) {
                    survey = user && surveyAnswer.items.find((x) => x.inputerId === currentUser.id);
                }

                if (callback) yield call(callback, survey);
            } catch (e) {
                yield call(error, "getSurveyAnswer：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}
app.model(noticeModel);
