import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberService, customerService, memberCompanyUserTypeService } from "@reco-w/member-service";

import { Namespaces, CertifyStatusEnum, CustomerTypeEnum, CustomerStatusEnum, operateSourceEnum } from "./common";
export namespace certifyFormModel {
    export const namespace = Namespaces.certifyForm;

    export const state: any = freeze({
        ...CoreState,
        PageIndex: 1,
        isInRequest: true,
    });
    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ message, inputData, callback }, { call, put }) {
            put({ type: "showLoading" });
            try {
                yield put({ type: "input", data: { ...inputData } });
                yield put({ type: "getMember", message, callback });
                yield put({ type: "getCustomer", message });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取会员类型
         * @param { fail, companyUserTypeId }
         * @param { call, put }
         */
        *getUserTypes({ fail, companyUserTypeId }, { call, put }) {
            try {
                const usertypes = yield call(memberCompanyUserTypeService.getList, { isSupportFrontAuth: true });

                let usertype;
                if (companyUserTypeId) {
                    usertype = usertypes.find((x) => x.id === companyUserTypeId);
                    yield put({ type: "input", data: { companyUserTypeId: usertype && companyUserTypeId } });
                }

                yield put({
                    type: "input",
                    data: { usertypes, usertype },
                });
            } catch (message) {
                fail!(message.errmsg);
            }
        },
        /**
         * 获取当前会员
         * @param { message, callback }
         * @param { call, put, select }
         */
        *getMember({ message, callback }, { call, put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, message });
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser`, message });
                const memberState: any = yield select((state) => state[commonNamespaces.memberCommon]),
                    member = memberState.member,
                    user = memberState.user;
                if (member && member.status === CertifyStatusEnum.allow) {
                    yield put({ type: "input", data: { submitSuccess: true } });
                }

                yield put({ type: "getUserTypes", message, companyUserTypeId: member?.companyUserTypeId });
                yield put({ type: "getInitCustomer", message, companyId: !member?.isNewCompanyAuthentication && member?.companyId, companyName: member?.companyName });

                yield put({
                    type: "input",
                    data: {
                        Account: user && user.currentUser,
                        realname: (user && user.currentUser && user.currentUser.realName) || "",
                        mobile: (user && user.currentUser && user.currentUser.mobile) || "",
                        currentUser: user && user.currentUser,
                        member,
                        companyId: !member?.isNewCompanyAuthentication && member?.companyId,
                        // companyUserTypeId: member?.companyUserTypeId,
                        userId: user && user.currentUser && user.currentUser.id,
                    },
                });

                if (callback) {
                    yield call(callback, member);
                }
            } catch (e) {
                yield call(message!.error, "getMember：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 认证
         * @param { message, callback, updatamemberID }
         * @param { call, put, select }
         */
        *certify({ message, callback, updatamemberID }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                yield yield put({ type: `${commonNamespaces.memberCommon}/cleanMemberInfo`, message });

                const state: any = yield select((state) => state[Namespaces.certifyForm]),
                    usertype = state!.usertype;

                let params2 = {
                    accountId: state!.userId,
                    // companyDepartment: department,
                    companyId: state!.company && state!.company.customerId,
                    companyName: state!.company && state!.company.customerName,
                    companyUserTypeId: usertype.id,
                    companyUserTypeName: usertype.name,
                    parkId: getLocalStorage("parkId"),
                    parkName: getLocalStorage("parkName"),
                    realName: state!.realname,
                    mobile: state!.mobile,
                    operateSource: operateSourceEnum.web,
                };
                // const certify = yield call(memberService.certify, params2);
                let certify;
                if (updatamemberID) {
                    certify = yield call(memberService.put, updatamemberID, params2);
                } else {
                    certify = yield call(memberService.post, params2);
                }
                yield call(callback, certify);
            } catch (e) {
                yield call(message!.error, "certify：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },

        /**
         * 获取初始化的企业
         * @param { params, message, companyId, companyName }
         * @param { call, put }
         */
        *getInitCustomer({ params, message, companyId, companyName }, { call, put }) {
            try {
                if (companyId) {
                    const data = yield call(customerService.getPaged, {
                        parkId: getLocalStorage("parkId"),
                        status: CustomerStatusEnum.deal,
                        customerType: CustomerTypeEnum.company,
                        key: companyName,
                        ...params,
                    });

                    let company = data?.items?.find((x) => x.customerId === companyId);

                    yield put({ type: "input", data: { company } });
                }
            } catch (e) {
                yield call(message!.error, "getCustomer：" + e.errmsg);
            }
        },

        /**
         * 获取企业
         * @param { params, message}
         * @param { call, put, select }
         */
        *getCustomer({ params, message }, { call, put, select }) {
            try {
                let state = yield select((state) => state[Namespaces.certifyForm]),
                    { companyList } = state;

                let data = yield call(customerService.getPaged, {
                    parkId: getLocalStorage("parkId"),
                    status: CustomerStatusEnum.deal,
                    customerType: CustomerTypeEnum.company,
                    ...params,
                });

                let result: any = data.items;

                if (companyList && data.currentPage > 1) {
                    result.unshift(...companyList);
                }

                yield put({ type: "input", data: { companyList: result, TotalPages: data.totalPages, isInRequest: false } });
            } catch (e) {
                yield call(message!.error, "getCustomer：" + e.errmsg);
            }
        },
    };
}
app.model(certifyFormModel);
