import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { memberService } from "@reco-w/member-service";
import { Namespaces } from "./common";

export namespace memberManagerModel {
    export const namespace = Namespaces.memberManager;

    export type StateType = typeof state;

    export const state: any = freeze(
        {
            ...CoreState,
        },
        !0
    );

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({}, {}) {},
        /**
         * 获取列表
         */
        *getCertifyList({ error, data }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });

                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify` });
                const memberCommonState = yield select((state) => state[commonNamespaces.memberCommon]);

                let companyId = memberCommonState && memberCommonState.member && memberCommonState.member.companyId;

                let _data = {
                    parkId: data!.parkId,
                    pageIndex: data!.pageIndex,
                    pageSize: data!.pageSize,
                    status: data!.status === 0 ? null : data!.status,
                };

                const result = yield call(memberService.getCompanyStaffList, {
                    companyId,
                    ..._data,
                });

                yield put({ type: "input", data: { members: result.items, total: result.totalItems } });
            } catch (e) {
                yield call(error, "getCertifyList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 更新认证状态
         */
        *certifyUpdate({ error, callBack, data }, { call, put, select }) {
            try {
                yield put({ type: "showLoading" });
                const state = yield select((state) => state[Namespaces.memberManager]);

                let params = {
                    accountId: data!.accountId,
                    parkId: data!.parkId,
                    rejectReason: state!.reason,
                    status: data!.status,
                };

                yield call(memberService.certifyUpdate, params);

                yield call(callBack!);
            } catch (e) {
                yield call(error, "certifyUpdate：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(memberManagerModel);
