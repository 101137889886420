import { EffectsMapObject } from "dva";

import { ReducersMapObject } from "redux";

import { freeze } from "immer";

import { CoreEffects, CoreReducers, getLocalStorage, CoreState, attachService } from "@reco-m/core";

import { app, getSearchUrl } from "@reco-w/core-ui";

import { IParkBindTableNameEnum } from "@reco-w/common-common";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";

import { commentService } from "@reco-w/comment-service";

import { articleService } from "@reco-w/article-service";

import { menuLabel } from "@reco-w/bitech-common";

import {
    Namespaces,
    ARTICLE_HIDE_PICTURE_CODE,
    ARTICLE_LIST_DEFAULT_ORDERBY,
    ARTICLE_HOT_LIST_INPUT_TIME_ORDERBY,
    ArticleCatalogueEnum,
    ArticlePageSizeEnum,
    informationCenterCode,
} from "./common";

import { tagService } from "@reco-m/tag-service";

export namespace articleModel {
    export const namespace = Namespaces.article;

    export const state: any = freeze(
        {
            ...CoreState,
            isLoading: false,
            parentPathName: "",
            tagName: "",
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        /**
         * Init page
         * @param { message, catalogueCode }
         * @param { call, put }
         */
        *initPage({ message, catalogueCode }, { call, put }) {
            try {
                yield put({ type: "initState" });
                yield put({ type: "getIsHidePicture", message, catalogueCode });
                yield put({ type: "getHotArticleList", message, catalogueCode });

                yield put({ type: `${commonNamespaces.cmsCommon}/getHeadJson`, location: "/" + catalogueCode });
                yield put({ type: "input", data: { catalogueCode } });
            } catch (e) {
                yield call(message!.error, "initPage：" + e.errmsg);
            }
        },
        *getParentPathName({ data }, { put }) {
            const targetMenu = menuLabel[data];
            if (targetMenu) {
                yield put({ type: "input", data: { parentPathName: targetMenu.label } });
            }
        },
        *getTagName({ data }, { put, call }) {
            try {
                if (!data || !data.code || !data.tagValue) {
                    return;
                }
                const tags = yield call(tagService.getTagByTagClass, { tagClass: menuLabel[data.code].tagClass });
                const targetTag = tags.find((tag) => tag.tagValue === data.tagValue);
                yield put({ type: "input", data: { tagName: targetTag && targetTag.tagName } });
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * 处理Url，获取列表
         * @param { error, props }
         * @param { call, put }
         */
        *getArticleListByUrl({ error, props }, { call, put }) {
            try {
                const params = getSearchUrl(["pi", "ps"], props);

                let catalogueCode = props.match!.params.param;
                let tagValue = (getSearchUrl(["tagValue"], props) as any).tagValue;
                informationCenterCode.forEach((e) => {
                    if (e.code === catalogueCode) {
                        catalogueCode = ArticleCatalogueEnum.informationcenter;
                    }
                });

                yield put({
                    type: "input",
                    data: {
                        currentPage: Number(params["pi"]) || 1,
                        pageSize: Number(params["ps"]) || ArticlePageSizeEnum.defaultArticleList,
                        tagValue,
                        catalogueCode,
                    },
                });

                const data = {
                    key: params["k"],
                    pageIndex: params["pi"] || 1,
                    pageSize: params["ps"] || ArticlePageSizeEnum.defaultArticleList,
                    catalogueCode,
                    tagValue,
                };

                yield put({ type: "getArticleList", data: data });
            } catch (e) {
                yield call(error, "getArticleListByUrl：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取搜索的资讯列表
         * @param { error, callback, params, key, pageIndex, pageSize, searchType, SearchTypeEnum }
         * @param { call, put }
         */
        *getSearchArticleList({ error, callback, params, key, pageIndex, pageSize, searchType, SearchTypeEnum }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                if (
                    params &&
                    params["st"] &&
                    (Number(params["st"]) === SearchTypeEnum.article || Number(params["st"]) === SearchTypeEnum.notice) &&
                    (pageIndex !== params["pi"] || pageSize !== params["ps"] || searchType !== params["st"])
                ) {
                    callback && callback(params["pi"], params["ps"]);
                    const catalogueCode =
                        Number(params["st"]) === SearchTypeEnum.article
                            ? ArticleCatalogueEnum.article
                            : Number(params["st"]) === SearchTypeEnum.notice
                            ? ArticleCatalogueEnum.notification
                            : null;

                    yield put({
                        type: "input",
                        data: {
                            currentPage: Number(params["pi"]) || 1,
                            pageSize: Number(params["ps"]) || ArticlePageSizeEnum.articleSearch,
                            globalKey: params["gk"],
                        },
                    });

                    const data = {
                        key,
                        parkId: getLocalStorage("parkId"),
                        pageIndex: params["pi"] || 1,
                        pageSize: params["ps"] || ArticlePageSizeEnum.articleSearch,
                        catalogueCode,
                    };

                    yield put({
                        type: "getArticleList",
                        data: data,
                    });
                }
            } catch (e) {
                yield call(error, "getSearchArticleList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * Get article list
         * @param { error, data }
         * @param { call, put }
         */
        *getArticleList({ error, data }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                let articleList = yield call(articleService.getPaged, {
                    parkId: getLocalStorage("parkId"),
                    orderBy: ARTICLE_LIST_DEFAULT_ORDERBY,
                    isPublish: true,
                    ...data,
                });
                let ids = articleList.items.map((t) => t.id);

                // 获取评论数量
                const commentCount = yield put({ type: "getCommentCount", articleIds: ids });
                yield commentCount.then((d) => {
                    articleList = JSON.parse(JSON.stringify(articleList));
                    articleList.items.map((t) => (t.commentNumber = d[t.id] || 0));
                });
                if (data.catalogueCode === ArticleCatalogueEnum.newMedia) {
                    for (let i = 0; i < (articleList.items || []).length; i++) {
                        const id = articleList.items[i].id;
                        const attachs = yield call(attachService.getList, {
                            bindTableName: IParkBindTableNameEnum.article,
                            bindTableId: id,
                            customType: 2,
                        });
                        const pictures = yield call(attachService.getList, {
                            bindTableName: IParkBindTableNameEnum.article,
                            bindTableId: id,
                            customType: 0,
                        });
                        articleList.items[i].attachs = attachs;
                        articleList.items[i].pictures = pictures;
                    }
                }
                yield put({ type: "input", data: articleList });
            } catch (e) {
                yield call(error, "getArticleList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        /**
         * 获取评论数量
         * @param { message, articleIds }
         * @param { call }
         * @returns 评论数量
         */
        *getCommentCount({ message, articleIds }, { call }) {
            try {
                let obj = {
                    bindTableId: articleIds,
                    bindTableName: IParkBindTableNameEnum.article,
                    isUserDelete: false,
                    replyId: 0,
                };
                let commentCount = yield call(commentService.getCommentCount, obj);

                return commentCount;
            } catch (e) {
                yield call(message!.error, "getHotArticleList：" + e.errmsg);
            }
        },
        /**
         * 获取热门点击的资讯
         * @param { message, catalogueCode }
         * @param { call, put }
         */
        *getHotArticleList({ message, catalogueCode }, { call, put }) {
            try {
                const hotArticleList = yield call(articleService.getPaged, {
                    pageSize: ArticlePageSizeEnum.hotArticle,
                    pageIndex: 1,
                    parkId: getLocalStorage("parkId"),
                    orderBy: ARTICLE_HOT_LIST_INPUT_TIME_ORDERBY,
                    catalogueCode,
                    isPublish: true,
                });

                yield put({ type: "input", data: { hotArticleList: hotArticleList.items } });
            } catch (e) {
                yield call(message!.error, "getHotArticleList：" + e.errmsg);
            }
        },
        /**
         * 判断是否隐藏图片
         * @param { message, catalogueCode }
         * @param { call, put }
         */
        *getIsHidePicture({ message, catalogueCode }, { call, put }) {
            try {
                const result = ARTICLE_HIDE_PICTURE_CODE.some((x) => x === catalogueCode);
                yield put({ type: "input", data: { isHidePicture: result } });
            } catch (e) {
                yield call(message!.error, "getIsHidePicture：" + e.errmsg);
            }
        },
    };
}
app.model(articleModel);
