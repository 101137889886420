/** 特殊工单标记 */
export enum WorkOrderTypeEnum {
    /**
     * 服务机构
     */
    institution = "institution",
    /**
     * 入驻申请
     */
    checkIn = "checkIn",
    /**
     * 服务申请
     */
    marketApply = "marketapply",
}
