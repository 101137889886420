import { HttpService, resolveService } from "@reco-m/core";

/**
 * 问卷管理
 */
export class SurveyHttpService extends HttpService {
    constructor() {
        super("questionnaire/questionnaire");
    }
}
export const surveyService = resolveService(SurveyHttpService);

/**
 * 答卷管理
 */
export class SurveyAnswerHttpService extends HttpService {
    constructor() {
        super("questionnaire/answer");
    }
    /**
     * 我的问卷
     * @param data
     * @returns
     */
    mySurvey(data: any) {
        return this.httpGet("my", this.resolveRequestParams(data));
    }
    /**
     * 结束答卷
     * @param data
     * @returns
     */
    saveServey(data: any) {
        return this.httpPut("" + data.id, data.survey);
    }
    /**
     * 暂存答卷
     * @param data
     * @returns
     */
    tempSave(data) {
        return this.httpPut("temp-save/" + data.id, data.survey);
    }
    /**
     * 取消答卷
     * @param id
     * @returns
     */
    cancel(id) {
        return this.httpPut("cancel/" + id);
    }
}
export const surveyAnswerService = resolveService(SurveyAnswerHttpService);
