import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces } from "./common";

export namespace footerModel {
    export const namespace = Namespaces.footer;

    export const state: any = freeze(
        {
            ...CoreState,
        },
        !0
    );

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        init() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
    };
}

export function registerPageFooterModel(model?: any) {
    app.model(model || footerModel);
}
