import { MyApplyTabTypeEnum } from "@reco-w/my-models";

export enum Namespaces {
    market = "market",
    marketSearch = "marketSearch",
    marketDetail = "marketDetail",
    myMarket = "myMarket",
    common = "common",
    product = "product",
    productSearch = "productSearch",
    productDetail = "productDetail",
    productApply = "productApply",
    wrokorderCreate = "wrokorderCreate",
    addProduct = "addProduct",
    MyMarketIn = "MyMarketIn",
    marketInDetailModel = "marketInDetailModel",
}

/**
 * 服务产品状态tab值
 */
export enum MyProductTabEnum {
    all = "999",
    /**
     * 上架
     */
    valid = "0",
    /**
     * 下架
     */
    unValid = "1",
    /**
     * 审核
     */
    audit = "2",
    /**
     * 退回
     */
    back = "3",
    /**
     * 取消
     */
    cancel = "4",
}

export enum MarketTypeEnum {
    /**
     * 服务类型
     */
    serviceType = 1,
    /**
     * 服务城市
     */
    serviceCity = 2,
    /**
     * 智能排序
     */
    intelligenceSort = 3,
    /**
     * 时间排序
     */
    timeSort = 1,
    /**
     * 热度排序
     */
    heatSort = 2,
    /**
     * 计费方式
     */
    chargeType = 4,
    /**
     * 免费
     */
    chargeFree = "1",
    /**
     * 面议
     */
    chargeDiscuss = "2",
    /**
     * 收费
     */
    chargeToll = "3",
}

export enum AddProductTagEnum {
    serviceCatalogues = 1,
    serviceObjects = 2,
    chargeMode = 3,
}

/** 排序处枚举 */
export enum SortMarketWordEnum {
    inputTime = "inputTime",
    popularity = "applyNumber",
}
/** END 排序处枚举 */

/**
 * 服务联盟场景配置枚举
 */
export enum SceneConfigEnum {
    /**
     * 机构受理
     */
    institutionAccept = 1,
    /**
     * 平台受理
     */
    platformAccept = 2,
    /**
     * 平台展示
     */
    platformShow = 3,
}

/**
 * 服务机构、产品 状态枚举
 */
export enum InstitutionStatusEnum {
    /**
     * 审核未通过
     */
    notPass = -1,
    /**
     * 待审核
     */
    waitAudit = 0,
    /**
     * 审核通过
     */
    pass = 1,
    /**
     * 取消审核
     */
    cancel = 2,
}

/**
 * 服务机构、产品 来源枚举
 */
export enum InstitutionSourceEnum {
    /**
     * Web
     */
    web = "Web",
    /**
     * PC
     */
    pc = "PC",
    /**
     * APP
     */
    app = "APP",
}

/**
 * 服务机构 附件枚举
 */
export enum InstitutionCustomTypeEnum {
    /**
     * 机构LOGO
     */
    logo = 1,
    /**
     * 案例附件
     */
    case = 2,
    /**
     * 机构资质
     */
    qualification = 3,
}

/**
 * 服务产品 服务价格枚举
 */
export enum ProductChargeModeEnum {
    /**
     * 免费
     */
    free = "1",
    /**
     * 面议
     */
    discuss = "2",
    /**
     * 收费
     */
    charge = "3",
}

/**
 * 我的服务机构tab
 */
export enum MyMarketTabEnum {
    /**
     * 服务机构
     */
    market = "1",
    /**
     * 服务受理
     */
    marketApply = "2",
    /**
     * 产品发布
     */
    product = "3",
}
/**
 * 我的服务机构tab
 */
export enum TabTypeEnum {
    /**
     * 机构详情
     */
    marketDetails = "1",
    /**
     * 机构资质
     */
    marketQCheck = "2",
    /**
     * 服务产品
     */
    marketProduct = "3",
}

/**
 * 服务类型标签code
 */
export const INSTITUTION_SERVICE_TYPE_TAG_CODE = "CATALOGUE/fuwlx";
/**
 * 服务对象标签code
 */
export const INSTITUTION_SERVICE_OBJECT_TAG_CODE = "INSTITUTION/fuwdx";
/**
 * 服务价格单位标签code
 */
export const INSTITUTION_SERVICE_PRICE_UNIT_TAG_CODE = "INSTITUTION/jiagdw";

/**
 * 服务产品收费方式集合
 */
export const INSTITUTION_PRODUCT_CHARGE_MODE: any = [
    { id: ProductChargeModeEnum.free, tagValue: ProductChargeModeEnum.free, tagName: "免费" },
    { id: ProductChargeModeEnum.discuss, tagValue: ProductChargeModeEnum.discuss, tagName: "面议" },
    { id: ProductChargeModeEnum.charge, tagValue: ProductChargeModeEnum.charge, tagName: "收费" },
];
/**
 * 服务受理工单状态集合
 */
export const INSTITUTION_APPLY_ORDER_TABS = [
    { title: "全部", i: 0, status: null, topicStatus: "" },
    { title: "待受理", i: 1, status: MyApplyTabTypeEnum.waiting, topicStatus: "" },
    { title: "处理中", i: 2, status: MyApplyTabTypeEnum.handling, topicStatus: "" },
    { title: "已完成", i: 3, status: MyApplyTabTypeEnum.finish, topicStatus: "" },
    { title: "已退回", i: 5, status: MyApplyTabTypeEnum.back, topicStatus: "" },
];
