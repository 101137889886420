import React from "react";
// import { Row, Col, Typography, Button, Modal } from "antd";

import { template } from "@reco-m/core";
import { ViewComponent } from "@reco-w/core-ui";
import { Namespaces, footerModel } from "@reco-w/layout-models";

export namespace PageFooter {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {}
    export interface IState extends ViewComponent.IState, footerModel.StateType {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.footer;
        year = new Date().getFullYear();

        render() {
            return (
                <div className="footer pd-footer">
                    <div className="container">
                        <p>
                            <span>共青团上海市浦东新区委员会</span>
                            <span>邮箱：tw@pudong.gov.cn</span>
                            <span>电话：021-20742948</span>
                            <span>地址：上海市浦东新区锦安东路475号4号楼4楼</span>
                        </p>
                        <p>
                            <span className="hand" onClick={() => window.open("https://www.beian.gov.cn/portal/index.do")}>
                                沪ICP备12014178号
                            </span>
                            <span>公网安备31011502006404号</span>
                        </p>
                    </div>
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.footer]);
}
