import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, CoreState, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { tagService } from "@reco-m/tag-service";
import { menuLabel } from "@reco-w/bitech-common";
import { articleService } from "@reco-w/article-service";
import { ARTICLE_LIST_DEFAULT_ORDERBY } from "@reco-w/article-models";
import { Namespaces } from "./common";

export namespace secondModel {
    export const namespace = Namespaces.second;

    export const state: any = freeze({
        ...CoreState,
        tags: [],
    });

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        *initPage({ data }, { put }) {
            // yield put({ type: "initState" });
            yield put({ type: "getTags", data: menuLabel[data.code].tagClass });
        },
        *getTags({ data }, { put, call }) {
            try {
                if (!data) {
                    console.log("没有标签");
                    return;
                }
                const tags = yield call(tagService.getTagByTagClass, { tagClass: data });
                yield put({ type: "input", data: { tags } });
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Get article list
         * @param { error, data }
         * @param { call, put }
         */
        *getArticleList({ error, data, callback }, { call, put }) {
            try {
                yield put({ type: "showLoading" });
                let articleList = yield call(articleService.getPaged, {
                    parkId: getLocalStorage("parkId"),
                    orderBy: ARTICLE_LIST_DEFAULT_ORDERBY,
                    isPublish: true,
                    ...data,
                });
                callback && callback(articleList);
                return articleList;
            } catch (e) {
                console.error(e);
                yield call(error, "getArticleList：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(secondModel);
