import { ITag, IOption } from "@reco-w/bitech-common";

export function tagsToOptions(tags: ITag[]): IOption[] {
    return (
        tags &&
        tags.map((tag) => {
            return {
                title: tag.tagName,
                value: Number(tag.tagValue),
            };
        })
    );
}
