export enum Namespaces {
    second = "second",
}

export enum SecondTypeEnum {
    toList = "toList",
    toDetail = "toDetail",
}

// 只显示，不进入详情
export const notToDetailCode = ["weixgzh", "douy", "Bz"];

export const imgScreenFromTagCode = ["daxskcjjsb", "zhuantjksp", "jingpwtk", "weixgzh", "douy", "Bz"];
