export enum Namespaces {
    article = "article",
    articleDetail = "articleDetail",
}

/**
 * 资讯数据目录代码
 */
export enum ArticleCatalogueEnum {
    /**
     * 通知公告
     */
    notification = "TZTG",
    /**
     * 动态资讯
     */
    article = "DTZX",
    /** 工作要讯 */
    workmessage = "GZYX",
    /** 基层动态 */
    grassrootsdynamics = "JCDT",
    /** 浦东青年出品 */
    producedbyyouth = "PDQNCP",
    /** 媒体聚焦 */
    mediafocus = "MTJJ",
    /** 青年大学习 */
    youngstuty = "QNDXX",
    /** 部室职能 */
    partroomfunction = "BSZN",
    // 工作要讯
    workNews = "GZYX",
    // 基层动态
    grassrootsDynamics = "JCDT",
    // 新媒体矩阵
    newMedia = "XMTJZ",
    // 青马工程
    qingmaProject = "QMGC",
    // 国旗下成长
    national = "GQXCZ",
    // 菁英聚浦东
    eliteGatherPudong = "JYJPD",

    // 青年生力军
    youthForce = "QNSLJ",
    // 创新创业
    innovationEntrepreneurship = "CXCY",
    // 岗位建功
    postMeritoriousService = "GWJG",
    // 就业启航
    employmentSetSail = "JYQH",
    // 活动报名
    activity = "activity",
    // 青春志愿汇
    volunteerMeeting = "QCZYH",
    // 青社沙龙
    qingsheSalon = "QSSL",
    // 青春篷篷车
    youthCaravan = "QCPPC",
    // 青创合伙人
    QingChuangPartner = "QCHHR",
    // 青春守护者
    guardianOfYouth = "QCSHZ",
    // 团聚爱
    reunionLove = "TJA",
    // 青年健康节
    youthHealthFestival = "QNJKJ",
    // 亲子共育
    parentChildEducation = "QZGY",
    // 资料中心
    informationcenter = "ZLZX",
    // 大事记和荣誉
    daShiJiHeRongYu = "dasjhry",
    // 网上团校
    wangShangTuanXiao = "wangstx",
    // 浦东青年微信公众号热文
    weiXinGongZhongHao = "pudqnwxgzhrw",
    // 浦东青年与十四五
    puDongQingNianYuShiSiWu = "QNYSSW",
    // 阵地导航
    map = "map",
}
export const homeCode = [ArticleCatalogueEnum.workNews, ArticleCatalogueEnum.grassrootsDynamics, ArticleCatalogueEnum.notification];

export const informationCenterCode = [
    { code: ArticleCatalogueEnum.daShiJiHeRongYu, value: 1 }, // 大事记和荣誉
    { code: ArticleCatalogueEnum.wangShangTuanXiao, value: 2 }, // 网上团校
    { code: ArticleCatalogueEnum.weiXinGongZhongHao, value: 3 }, // 浦东青年微信公众号热文
];

/**
 * 默认pageSize
 */
export enum ArticlePageSizeEnum {
    /**
     * 热门文章
     */
    hotArticle = 5,
    /**
     * 默认文章pageSize
     */
    defaultArticleList = 8,
    /**
     * 文章查询列表pageSize
     */
    articleSearch = 10,
    /**
     * 所有
     */
    all = 999,
}
/**
 * 列表页不显示图片的资讯Code
 */
export const ARTICLE_HIDE_PICTURE_CODE = [ArticleCatalogueEnum.notification];
/**
 * 资讯列表接口默认排序
 */
export const ARTICLE_LIST_DEFAULT_ORDERBY = "isTop desc,sequence asc,publishTime desc";
/**
 * 热门资讯默认排序
 */
export const ARTICLE_HOT_LIST_DEFAULT_ORDERBY = "viewCount desc";
/**
 * 热门资讯默认排序
 */
export const ARTICLE_HOT_LIST_INPUT_TIME_ORDERBY = "inputTime desc";
/**
 * 资讯数据目录code
 */
export const ARTICLE_ROOT_CODE = "ARTICLE_PARK";
