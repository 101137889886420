export enum Namespaces {
    notice = "notice"
}
export enum NotificationTypesEnum {
    unRead = "1"
}

export enum MailBoxTypeEnum {
    mailBox2 = 2
}
