import { HttpService, resolveService } from "@reco-m/core";
export class StateFlowHttpService extends HttpService {
    constructor() {
        super("stateflow/project");
    }

    createState(data: any) {
        return this.httpPost("create-state", data);
    }

    getState(data: any) {
        return this.httpGet("state", this.resolveRequestParams(Object.assign({ viewAsCreator: true }, data)));
    }

    getStateVariables(data: any) {
        return this.httpGet("state-variables", this.resolveRequestParams(data));
    }

    transitState(data) {
        return this.httpPost("transit-state", data);
    }

    getStateRoutes(data) {
        return this.httpGet("state-routes", this.resolveRequestParams(data));
    }
}
export const stateFlowService = resolveService(StateFlowHttpService);
