import { IParkBindTableNameEnum } from "@reco-w/common-common";
// import { getObjectProp } from "@reco-m/core";
// import { InvoiceTypeEnum } from "@reco-w/invoice-models";
import { /* IntergralRuleTypeEnum, CurrentIntergralTypeEnum */ MemberManagerEnum } from "@reco-w/member-models";
import { MySettingTabEnum } from "@reco-w/my-models";
import { ResourceTypeEnum, PriceTypeEnum, NotificationTypeEnum } from ".";
/**
 * 获取资源类型
 * @param type
 * @returns
 */
export function getResourceType(type: number) {
    switch (type) {
        case ResourceTypeEnum.station:
            return "工位";
        case ResourceTypeEnum.meetingRoom:
            return "会议室";
        case ResourceTypeEnum.advertising:
            return "广告位";
        case ResourceTypeEnum.venue:
            return "场地";
        default:
            return;
    }
}

export function getResourceRoute(type: number) {
    switch (type) {
        case ResourceTypeEnum.station:
            return "positionstation";
        case ResourceTypeEnum.meetingRoom:
            return "roommeet";
        case ResourceTypeEnum.advertising:
            return "positionadvert";
        case ResourceTypeEnum.venue:
            return "roomarea";
        default:
            return;
    }
}

export function getPriceType(type: number) {
    switch (type) {
        case PriceTypeEnum.contractHalfHour:
            return "元/半小时";
        case PriceTypeEnum.contractHour:
            return "元/小时";
        case PriceTypeEnum.contractDay:
            return "元/天";
        case PriceTypeEnum.contractMonth:
            return "元/月";
        case PriceTypeEnum.contractQuarter:
            return "元/季度";
        case PriceTypeEnum.contractHalfYear:
            return "元/半年度";
        case PriceTypeEnum.contractYear:
            return "元/年度";
        case PriceTypeEnum.contractDayPerSquareMeter:
            return "元/月/平方米";
        case PriceTypeEnum.contractQuarterPerSquareMeter:
            return "元/季度/平方米";
        case PriceTypeEnum.contractHalfYearPerSquareMeter:
            return "元/半年度/平方米";
        case PriceTypeEnum.contractYearPerSquareMeter:
            return "元/年度/平方米";
        case PriceTypeEnum.saleYuanPerSquareMeter:
            return "元/平方米";
        case PriceTypeEnum.saleYuanPerDay:
            return "元";
        default:
            return;
    }
}

export function getNotificationType(type: number) {
    switch (type) {
        case NotificationTypeEnum.message:
            return "通知";
        case NotificationTypeEnum.todo:
            return "待办";
        case NotificationTypeEnum.remind:
            return "app";
        case NotificationTypeEnum.sms:
            return "短信";
        case NotificationTypeEnum.email:
            return "邮件";
        case NotificationTypeEnum.wechat:
            return "微信";
        case NotificationTypeEnum.wechatEP:
            return "企业微信";
        default:
            return;
    }
}

export function GetPopupMenu() {
    return [
        {
            title: "物业报修",
            backgroundColor: "#fdcc91",
            icon: "iconbaoxiu",
            url: `/workorder/wybx`,
        },
        {
            title: "访客预约",
            backgroundColor: "#f9c2f9",
            icon: "iconrenyuanguanli",
            url: `/workorder/visitor`,
        },
        {
            title: "政策咨询",
            backgroundColor: "#adb0e7",
            icon: "iconshouye",
            url: `/workorder/zczx?ZCZT=政策综合咨询`,
        },
        {
            title: "会议预订",
            backgroundColor: "#c1f2f6",
            icon: "iconhuiyishi",
            url: `/resource/${getResourceRoute(ResourceTypeEnum.meetingRoom)}`,
        },
        {
            title: "广告预订",
            backgroundColor: "#c6f084",
            icon: "iconguanggao",
            url: `/resource/${getResourceRoute(ResourceTypeEnum.advertising)}`,
        },
        {
            title: "意见反馈",
            backgroundColor: "#c9dd00",
            icon: "iconyijianfankui",
            url: `/workorder/yjfk?ZXMS=`,
        },
    ];
}

function GetMyMenuChild1() {
    return [
        {
            key: "/my",
            url: `/my`,
            icon: "iconhome-g",
            title: "我的主页",
        },
        {
            key: "/my/myserve",
            url: `/my/myserve?c=QB`,
            icon: "iconjilu1",
            title: "服务记录",
        },
        // {
        //     key: "/my/order/0",
        //     url: `/my/order/0`,
        //     icon: "iconziyuan",
        //     title: "资源订单",
        // },
        {
            key: "/my/myDemandSubmission",
            url: `/my/myDemandSubmission`,
            icon: "iconhome-g",
            title: "需求提报",
        },
    ];
}

// const openRecruit = getObjectProp(client, "app.openRecruit", false),
//     openEnterpriseHome = getObjectProp(client, "app.openEnterpriseHome", false),
//     openAdminiApproval = getObjectProp(client, "app.openAdminiApproval", false),
//     openPolicyDeclare = getObjectProp(client, "app.openPolicyDeclare", false);

function GetMyMenuChild2() {
    return [
        {
            key: "/my/mynotice",
            url: `/my/mynotice`,
            icon: "iconxiaoxi",
            title: "消息中心",
        },
        {
            key: "/my/myfavorte",
            url: `/my/myfavorte?mf=${IParkBindTableNameEnum.activity}`,
            icon: "iconshoucang",
            title: "我的收藏",
        },
        {
            key: "/my/myactivity",
            url: `/my/myactivity`,
            icon: "iconhuodong",
            title: "我的活动",
        },
        {
            key: "/my/mycircle",
            url: `/my/mycircle`,
            icon: "iconquanzi",
            title: "我的话题",
        },
        // {
        //     key: "/my/mysurvey",
        //     url: `/my/mysurvey`,
        //     icon: "iconwenjuan",
        //     title: "问卷调研",
        // },
    ];
}

// function GetMyMenuChild3() {
//     return [
//         {
//             key: "/my/myinvoice",
//             url: `/my/invoice?s=${InvoiceTypeEnum.all}`,
//             icon: "iconfapiao",
//             title: "我的发票",
//         },
//         {
//             key: "/my/mine",
//             url: `/my/mine?s=1`,
//             icon: "iconshoucang",
//             title: "我的优惠券",
//         },
//         {
//             key: "/my/myintegral",
//             url: `/my/myintegral?rt=${IntergralRuleTypeEnum.earn}&it=${CurrentIntergralTypeEnum.person}&tt=1`,
//             icon: "iconhuodong",
//             title: "积分管理",
//         },
//     ];
// }

function GetMyMenuChild4() {
    return [
        // {
        //     key: "/my/myproject",
        //     icon: "iconruzhu",
        //     title: "入驻项目",
        //     special: "checkOrder",
        // },
        {
            key: "/my/mysetting",
            url: `/my/mysetting?tt=${MySettingTabEnum.user}`,
            icon: "iconshezhi",
            title: "账号设置",
        },
        {
            key: "/my/myperson",
            url: `/my/myperson?tt=${MemberManagerEnum.manage}`,
            icon: "iconguanliyuan",
            isAdminCertify: true,
            title: "员工管理",
        },
        {
            key: "/my/mymarket",
            icon: "iconruzhu",
            title: "服务机构",
            isAdminCertify: true,
            special: "institution",
        },
    ];
}

// function GetMyMenuChild5() {
//     const result: any[] = [];

//     if (openEnterpriseHome) {
//         result.push({
//             key: "/my/myEnterprise",
//             url: "/my/myEnterprise",
//             icon: "iconruzhu",
//             title: "企业主页",
//         });
//     }

//     if (openRecruit) {
//         result.push({
//             key: "/my/myRecruit",
//             url: "/my/myRecruit",
//             icon: "iconruzhu",
//             title: "企业招聘",
//         });
//     }

//     if (openPolicyDeclare) {
//         result.push({
//             key: "/my/myDeclare",
//             url: `/my/myDeclare?c=QB`,
//             icon: "iconjilu1",
//             title: "政策申报",
//         });
//     }

//     if (openAdminiApproval) {
//         result.push({
//             key: "/my/myApproval",
//             url: `/my/myApproval?c=QB`,
//             icon: "iconjilu1",
//             title: "行政审批",
//         });
//     }

//     return result;
// }

function GetMyMenuChild6() {
    return [
        {
            key: "/my/declare",
            url: `/my/declare`,
            icon: "iconxiaoxi",
            title: "基金申报",
        },
        {
            key: "/my/declare/problem",
            url: `/my/declare/problem`,
            icon: "iconxiaoxi",
            title: "常见问题",
        },
    ];
}

// function GetMyMenuChild7() {
//     return [
//         {
//             key: "/my/declare/appliedProject",
//             url: `/my/declare/appliedProject`,
//             icon: "iconxiaoxi",
//             title: "已申报项目",
//         },
//         {
//             key: "/my/declare/finishProject",
//             url: `/my/declare/finishProject`,
//             icon: "iconxiaoxi",
//             title: "申报通过项目",
//         },
//         {
//             key: "/my/declare/backProject",
//             url: `/my/declare/backProject`,
//             icon: "iconxiaoxi",
//             title: "已退回项目",
//         },
//         {
//             key: "/my/declare/draftProject",
//             url: `/my/declare/draftProject`,
//             icon: "iconxiaoxi",
//             title: "待提交项目",
//         },
//         {
//             key: "/my/bankaccount",
//             url: `/my/bankaccount`,
//             icon: "iconxiaoxi",
//             title: "拨款项目更新",
//         },
//     ];
// }

// function GetMyMenuChild8() {
//     return [
//         {
//             key: "/my/batchresult",
//             url: `/my/batchresult`,
//             icon: "iconxiaoxi",
//             title: "待评审项目",
//         },
//         {
//             key: "/my/batchresult/send",
//             url: `/my/batchresult/send?isValid=1`,
//             icon: "iconxiaoxi",
//             title: "已提交项目",
//         },
//     ];
// }

export function GetMyMenu() {
    return [GetMyMenuChild1(), GetMyMenuChild2(), GetMyMenuChild6(), GetMyMenuChild4()].filter((x) => x.length);
}
