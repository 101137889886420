import { EffectsMapObject } from "dva";

import { getLocalStorage, mergeState } from "@reco-m/core";

import { tagService } from "@reco-m/tag-service";

import { Namespaces as commonNamespaces } from "@reco-w/common-models";
import { flowDataSourceService, flowNumberService, FlowApiHttpService } from "@reco-w/approval-service";

export const ApprovalBaseEffects: EffectsMapObject = {
    *getSerialNumber({ fail, data, params }, { call, put }) {
        try {
            yield put({ type: "showLoading" });
            const serialNumber = yield call(flowNumberService.getSerialNumber, data);
            let formData = params.formData,
                control = params.control,
                numbers: any = [];

            if (formData && !control.value) {
                const newForm = mergeState(formData, { key: `controls[${params.index}].value`, data: serialNumber });
                numbers.push({ controlCode: control.controlCode, key: params.actionArgs, value: serialNumber });

                yield put({
                    type: "input",
                    data: {
                        formData: newForm,
                        numbers: numbers,
                    },
                });
            } else {
                yield put({ type: "input", data: { serialNumber: serialNumber } });
            }
        } catch (error) {
            fail!(`${error.errmsg || error.toString()}`);
        } finally {
            yield put({ type: "hideLoading" });
        }
    },

    *getDataSource({ fail, data, callback }, { call, put }) {
        try {
            const dataSource = yield call(flowDataSourceService.getList, data);
            yield put({ type: "input", data: { dataSources: dataSource } });
            callback && callback(dataSource);
        } catch (error) {
            fail!(error.errmsg || error.toString());
        }
    },

    *execSql({ fail, source, params, callback }, { call, put }) {
        try {
            const result = yield call(flowDataSourceService.execSql, { code: source.code, flowId: source.flowId, params: params });
            const data = Array.isArray(result) ? result : typeof result !== "undefined" && result != null ? [result] : null;
            yield put({ type: "input", data: { [source.code]: data, [source.code + "_isInRequest"]: false } });
            callback && callback(data);
        } catch (error) {
            fail!(error.errmsg || error.toString());
        }
    },

    *execPageSql({ fail, source, params, callback, pageIndex, key, oldData }, { call, put }) {
        try {
            const result = yield call(flowDataSourceService.execPageSql, { code: source.code, flowId: source.flowId, params: Object.assign(params, { key }), pageIndex });
            let data = result.items;
            if (pageIndex && pageIndex > 1) {
                const old = oldData || [];
                data = old.concat(result.items);
            }
            yield put({ type: "input", data: { [source.code]: data, [source.code + "_isInRequest"]: false } });
            if (source.isPage) {
                yield put({
                    type: "input",
                    data: {
                        [source.code + "_currentPage"]: result.currentPage,
                        [source.code + "_totalPages"]: result.totalPages,
                        [source.code + "_key"]: key,
                    },
                });
            }
            callback && callback(data);
        } catch (error) {
            fail!(error.errmsg || error.toString());
        }
    },

    *execApi({ fail, source, params, callback, oldData }, { call, put }) {
        try {
            const controlName = source.commandText.slice(0, source.commandText.lastIndexOf("/"));
            let apiName = "";
            if (source.commandText.lastIndexOf("/") + 1 < source.commandText.length) {
                apiName = source.commandText.slice(source.commandText.lastIndexOf("/") + 1);
            }
            const apiService = new FlowApiHttpService(controlName);
            const result = yield call(apiService.getCustom.bind(apiService), apiName, params);
            let data = source.isPage ? result.items : Array.isArray(result) ? result : typeof result !== "undefined" && result != null ? [result] : null;
            if (params.pageIndex && params.pageIndex > 1) {
                const old = oldData || [];
                data = old.concat(result.items);
            }
            yield put({ type: "input", data: { [source.code]: data, [source.code + "_isInRequest"]: false } });
            if (source.isPage) {
                yield put({
                    type: "input",
                    data: {
                        [source.code + "_currentPage"]: result.currentPage,
                        [source.code + "_totalPages"]: result.totalPages,
                        [source.code + "_key"]: params.key,
                    },
                });
            }
            callback && callback(data);
        } catch (error) {
            fail!(error.errmsg || error.toString());
        }
    },

    *getTag({ fail, data }, { call, put }) {
        try {
            yield put({ type: "showLoading" });

            const tags = yield call(tagService.getTagByTagClass, { tagClass: data, unitId: getLocalStorage("UnitID") });

            yield put({ type: "input", data: { [data]: tags } });
        } catch (error) {
            fail!(error.errmsg || error.toString());
        } finally {
            yield put({ type: "hideLoading" });
        }
    },

    *getTags({ fail, data }, { call, put }) {
        try {
            yield put({ type: "showLoading" });
            const tags = yield call(tagService.getTagByTagClasses, { tagClass: data, unitId: getLocalStorage("UnitID") });

            yield put({ type: "input", data: { ...tags } });
        } catch (error) {
            fail!(error.errmsg || error.toString());
        } finally {
            yield put({ type: "hideLoading" });
        }
    },

    *getCurrentUser({ message }, { call, put, select }) {
        try {
            yield put({ type: "showLoading" });
            yield yield put({ type: `${commonNamespaces.memberCommon}/getCurrentUser` });

            let user = yield select((state) => state[commonNamespaces.memberCommon]);
            yield put({
                type: "input",
                data: { currentUser: user.user?.currentUser },
            });
        } catch (error) {
            yield call(message!.error, error.errmsg);
        } finally {
            yield put({ type: "hideLoading" });
        }
    },
};
