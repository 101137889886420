// 我的左侧导航栏

import React from "react";
import {router} from "dva";
import {Menu, Col, Drawer} from "antd";
import {template, setLocalStorage, getLocalStorage} from "@reco-m/core";
import {getParkCode, ViewComponent} from "@reco-w/core-ui";
import {MemberRoleNameBuiltInEnum, CertifyStatusEnum} from "@reco-w/member-models";
import {Namespaces, mymenuModel} from "@reco-w/my-models";
import {GetMyMenu} from "@reco-w/my-common";
import {InstitutionStatusEnum} from "@reco-w/market-models";

export namespace Mymenu {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {
    }

    export interface IState extends ViewComponent.IState, mymenuModel.StateType {
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.myMenu;
        certifyID;

        componentMount() {
            !this.isAuth() && this.goTo(`/${getParkCode()}/login`);
        }

        componentDidMount() {
            setTimeout(() => {
                this.dispatch({
                    type: "initPage", error: () => {
                    }
                });
            }, 1200);
            this.menuState();
            $(window).resize(() => {
                this.menuState();
            });
        }

        /**
         * 获取目录的key值
         * @returns
         */
        getMenuSelectedKeys() {
            const mySelectedMenuText = getLocalStorage("mySelectedMenu"),
                mySelectedMenus = mySelectedMenuText && mySelectedMenuText.split("?");
            let mySelectedMenu = mySelectedMenus && mySelectedMenus.length > 0 && mySelectedMenus[0];

            !mySelectedMenuText && setLocalStorage("mySelectedMenu", "/my");

            return mySelectedMenu ? [mySelectedMenu] : ["/my"];
        }

        renderAdminItem(child, url) {
            return (
                <Menu.Item key={child.key}>
                    <router.Link to={`/${getParkCode()}${url}`}></router.Link>
                    <i className={"icon " + child.icon}/>
                    <span>{child.title}</span>
                </Menu.Item>
            );
        }

        menuState() {
            let wh: any = $(window).width();
            if (wh < 1200) {
                this.dispatch({type: "input", data: {menuBoolean: true}});
            } else {
                this.dispatch({type: "input", data: {menuBoolean: false}});
            }
        }

        showDrawer = () => {
            this.dispatch({type: "input", data: {menuVisible: true}})
        };
        onClose = () => {
            this.dispatch({type: "input", data: {menuVisible: false}})
        };

        render() {
            const {state} = this.props,
                member = state!.member,
                checkOrderId = state!.checkOrderId,
                checkStateId = state!.checkStateId,
                institution = state!.institution,
                menuVisible = state!.menuVisible,
                menuBoolean = state!.menuBoolean;

            const {serviceInstitutionBasicFormVM: insBasic = {}} = institution || {};
            let MyMenu = GetMyMenu();
            return (
                <Col xs={24} xl={5} className="gutter-row">
                    <div className="phone-menu" onClick={this.showDrawer}><i className="icon icongengduo"/></div>
                    {
                        menuBoolean ? <Drawer
                            className=""
                            title=""
                            placement="left"
                            closable={false}
                            onClose={this.onClose}
                            visible={menuVisible}
                        >
                            {MyMenu &&
                            MyMenu.length > 0 &&
                            MyMenu.map((item: any, i) => (
                                <div key={i}>
                                    <Menu className="border my-menu" selectedKeys={this.getMenuSelectedKeys()}
                                          mode="inline"
                                          theme="light">
                                        {item.map((child) => {
                                            let url = child.url;

                                            if (child.special) {
                                                if (child.special === "checkOrder") {
                                                    url = checkOrderId ? `/my/myproject?type=checkIn&id=${checkOrderId}&sid=${checkStateId}` : `/workorder/ruzsq`;
                                                } else if (child.special === "institution") {
                                                    url = [InstitutionStatusEnum.waitAudit, InstitutionStatusEnum.pass, InstitutionStatusEnum.notPass].contains(insBasic.status)
                                                        ? `/my/mymarket`
                                                        : `/market/all`;
                                                }
                                            }

                                            return child.isAdminCertify ? (
                                                member &&
                                                member.companyUserTypeName === MemberRoleNameBuiltInEnum.manager &&
                                                member.status === CertifyStatusEnum.allow &&
                                                this.renderAdminItem(child, url)
                                            ) : (
                                                <Menu.Item key={child.key}>
                                                    <router.Link to={`/${getParkCode()}${url}`}></router.Link>
                                                    <i className={"icon " + child.icon}/>
                                                    <span>{child.title}</span>
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu>
                                    <div className="blank16"/>
                                </div>
                            ))}
                        </Drawer> : MyMenu &&
                            MyMenu.length > 0 &&
                            MyMenu.map((item: any, i) => (
                                <div key={i}>
                                    <Menu className="border my-menu" selectedKeys={this.getMenuSelectedKeys()}
                                          mode="inline"
                                          theme="light">
                                        {item.map((child) => {
                                            let url = child.url;

                                            if (child.special) {
                                                if (child.special === "checkOrder") {
                                                    url = checkOrderId ? `/my/myproject?type=checkIn&id=${checkOrderId}&sid=${checkStateId}` : `/workorder/ruzsq`;
                                                } else if (child.special === "institution") {
                                                    url = [InstitutionStatusEnum.waitAudit, InstitutionStatusEnum.pass, InstitutionStatusEnum.notPass].contains(insBasic.status)
                                                        ? `/my/mymarket`
                                                        : `/market/all`;
                                                }
                                            }

                                            return child.isAdminCertify ? (
                                                member &&
                                                member.companyUserTypeName === MemberRoleNameBuiltInEnum.manager &&
                                                member.status === CertifyStatusEnum.allow &&
                                                this.renderAdminItem(child, url)
                                            ) : (
                                                <Menu.Item key={child.key}>
                                                    <router.Link to={`/${getParkCode()}${url}`}></router.Link>
                                                    <i className={"icon " + child.icon}/>
                                                    <span>{child.title}</span>
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu>
                                    <div className="blank16"/>
                                </div>
                            ))
                    }
                </Col>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.myMenu]);
}
